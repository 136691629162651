import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import * as API from 'src/apis';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  token: string;
};

export async function getWorkspacesGatewaysSensorDataBeginEndTimestamp({
  workspaceId,
  token,
}: Props): Promise<API.WorkspaceGatewaysSensorDataBeginEndTimestamps> {
  return axios
    .get(
      `${API_ENDPOINT}/workspaces/${workspaceId}/gateways/sensor-data-begin-end-timestamp`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
