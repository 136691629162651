import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { InputField } from 'src/shared/ui/InputField';
import { useState } from 'react';
import * as API from 'src/apis';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { BreadcrumbsV3, BreadcrumbTitleV3 } from '../../commons/breadcrumbs';
import NegativeButton from '../../commons/buttons/Negative';
import PrimaryButton from '../../commons/buttons/Primary';
import PeriodInput, { Period } from './PeriodInput';
import PeriodDialog from './PeriodDialog';

const validationSchema = yup.object().shape({
  title: yup.string().max(50),
});

const initialValues = {
  title: '',
};

interface ReportsMakeNewProps {
  gateways: API.WorkspaceGatewaysSensorDataBeginEndTimestamp[];
}

export default function ReportsMakeNew({ gateways }: ReportsMakeNewProps) {
  return (
    <Box display="flex" flexDirection="column" rowGap={3.5}>
      <BreadCrumb />
      <NewReport gateways={gateways} />
    </Box>
  );
}

function BreadCrumb() {
  const intl = useIntl();

  return (
    <Box
      pl={{
        xs: 2,
        sm: 0,
      }}
    >
      <BreadcrumbsV3>
        <Link to="/reports">
          <BreadcrumbTitleV3 color="primary">
            {intl.formatMessage({
              id: 'pages.Reports.New.parentTitle',
            })}
          </BreadcrumbTitleV3>
        </Link>
        <BreadcrumbTitleV3>
          {intl.formatMessage({
            id: 'pages.Reports.New.title',
          })}
        </BreadcrumbTitleV3>
      </BreadcrumbsV3>
    </Box>
  );
}

function NewReport({
  gateways,
}: {
  gateways: API.WorkspaceGatewaysSensorDataBeginEndTimestamp[];
}) {
  const intl = useIntl();

  const initialPeriod = {
    beginAt: DateTime.now().minus({ months: 3 }).toMillis(),
    endAt: DateTime.now().toMillis(),
  };

  const [period, setPeriod] = useState({
    beginAt: initialPeriod.beginAt,
    endAt: initialPeriod.endAt,
  });

  const [openPeriodDialog, setOpenPeriodDialog] = useState(false);
  const [isPeriodDirty, setIsPeriodDirty] = useState(false);

  const handlePeriodChange = ({ beginAt, endAt }: Period) => {
    setPeriod({ beginAt, endAt });
  };

  const togglePeriodDialog = () => {
    setOpenPeriodDialog((prev) => !prev);
  };

  const checkPeriodDirty = () => {
    setIsPeriodDirty(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={4}
      bgcolor="common.white"
      borderRadius={1}
      border={{ xs: 'none', sm: '1px solid #828282' }}
      p={3}
      mx={{ xs: -1.5, sm: 0 }}
    >
      <HeadTitle>
        {intl.formatMessage({
          id: 'pages.Reports.New.headTitle',
        })}
      </HeadTitle>
      <FormikProvider value={formik}>
        <InputField
          label={intl.formatMessage({
            id: 'pages.Reports.New.reportTitle.label',
          })}
          placeholder={intl.formatMessage({
            id: 'pages.Reports.New.reportTitle.placeholder',
          })}
          helperText={intl.formatMessage({
            id: 'pages.Reports.New.reportTitle.helperText',
          })}
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={Boolean(formik.errors.title)}
          sx={{
            backgroundColor: 'common.white',
            borderRadius: 1,

            '& .MuiInputBase-root': {
              '& fieldset': {
                borderColor: '#828282',
              },
            },

            '& .MuiInputBase-input': {
              height: 28, // content-box, py: 8.5px + height: 28px = 45px
            },

            '& .MuiInputLabel-root': {
              color: '#141414',
            },
          }}
        />

        <Box width={{ sm: 286 }} minWidth={{ sm: 286, lg: 380 }}>
          <PeriodInput
            label={intl.formatMessage({
              id: 'pages.Reports.New.period.label',
            })}
            placeholder={intl.formatMessage({
              id: 'pages.Reports.New.period.placeholder',
            })}
            helperText={intl.formatMessage({
              id: 'pages.Reports.New.period.helperText',
            })}
            period={period}
            isDirty={isPeriodDirty}
            toggleDialog={togglePeriodDialog}
          />

          <PeriodDialog
            loggingId="PeriodDialog"
            open={openPeriodDialog}
            title={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodDialog.title',
            })}
            content={
              <Typography color="#141414">
                {intl.formatMessage({
                  id: 'pages.Reports.New.period.dialogContent',
                })}
              </Typography>
            }
            initialPeriod={initialPeriod}
            period={period}
            isLimitted={false}
            onConfirm={handlePeriodChange}
            onDirty={checkPeriodDirty}
            confrimText={intl.formatMessage({
              id: 'pages.Reports.New.period.dialogConfirmButton',
            })}
            close={togglePeriodDialog}
          />
        </Box>

        <TargetGateway gateways={gateways} />

        <Box display="flex" columnGap={2} justifyContent="flex-end">
          <BackButton />
          <MakeNewButton />
        </Box>
      </FormikProvider>
    </Box>
  );
}

function TargetGateway({
  gateways,
}: {
  gateways: API.WorkspaceGatewaysSensorDataBeginEndTimestamp[];
}) {
  const intl = useIntl();

  const [selectedGateway, setSelectedGateway] = useState(gateways[0]?.deviceId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGateway(event.target.value);
  };

  return (
    <Box>
      <Box pb={2}>
        <Typography
          fontSize={{ xs: 13, sm: 18 }}
          fontWeight={700}
          color="#141414"
        >
          {intl.formatMessage({
            id: 'pages.Reports.New.targetGateway.title',
          })}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <RadioGroup
          aria-label="TargetGateway"
          name="TargetGateway"
          value={selectedGateway}
          onChange={handleChange}
        >
          {gateways.map((gateway) => (
            <FormControlLabel
              key={gateway.deviceId}
              value={gateway.deviceId}
              control={
                <Radio
                  size="small"
                  sx={{
                    color: 'primary.main',
                    '& .MuiSvgIcon-root': { width: 18, height: 18 },
                  }}
                />
              }
              label={
                <Typography fontSize={{ xs: 13, sm: 16 }}>
                  {gateway.gatewayName}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
}

function BackButton() {
  const intl = useIntl();

  return (
    <NegativeButton>
      {intl.formatMessage({
        id: 'pages.Reports.New.backButton',
      })}
    </NegativeButton>
  );
}

function MakeNewButton() {
  const intl = useIntl();
  return (
    <PrimaryButton>
      {intl.formatMessage({
        id: 'pages.Reports.New.makeNewButton',
      })}
    </PrimaryButton>
  );
}

function HeadTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      pl={1.5}
      fontSize={18}
      fontWeight="bold"
      borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
    >
      {children}
    </Typography>
  );
}
