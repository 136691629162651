import { useAuth0 } from '@auth0/auth0-react';
import { type QueryKey, useQuery } from '@tanstack/react-query';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import * as API from 'src/apis';
import { awsRum } from 'src/utils/rum';

type ErrorCode =
  | 'bad_request'
  | 'permission_denied'
  | 'not_found'
  | 'emergency_maintenance'
  | 'unknown_error';

type Result =
  | { status: 'hasError'; errorCode: ErrorCode }
  | { status: 'loading' }
  | {
      status: 'succeeded';
      data: Awaited<ReturnType<typeof API.getWorkspaceReports>>;
      keys: QueryKey;
    };

export function useGetWorkspacesReports(workspace: API.Workspace): Result {
  const { getAccessTokenSilently } = useAuth0();

  const workspaceId = workspace.workspaceId;
  const queryKey = Keys.reports.lists(workspaceId);

  const result = useQuery<
    Awaited<ReturnType<typeof API.getWorkspaceReports>>,
    API.Error
  >({
    queryKey,
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      return API.getWorkspaceReports({
        workspaceId,
        token,
      });
    },

    refetchOnWindowFocus: false,
  });

  if (result.isLoading) {
    return { status: 'loading' };
  }

  if (result.isError) {
    const error = result.error as API.Error;
    switch (error.code) {
      case 'not_found':
      case 'permission_denied':
      case 'emergency_maintenance':
        return { status: 'hasError', errorCode: error.code };
      case 'bad_request':
      case 'unknown_error':
      default:
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return { status: 'hasError', errorCode: 'unknown_error' };
    }
  }

  return {
    status: 'succeeded',
    data: {
      reports: result.data?.reports ?? [],
    },
    keys: queryKey,
  };
}
