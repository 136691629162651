import { DateTime } from 'luxon';

const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const JP_DATE_FORMAT = 'yyyy年M月d日';

export function toYmd(date: DateTime): string {
  return date.toFormat(ISO_DATE_FORMAT);
}

export function toJPYmd(date: DateTime): string {
  return date.toFormat(JP_DATE_FORMAT);
}

export function fromUnixMs(unixMs: number): DateTime {
  return DateTime.fromMillis(unixMs).setZone('Asia/Tokyo');
}
