import { CalendarTodaySharp } from '@mui/icons-material';
import { InputField } from 'src/shared/ui/InputField';
import { fromUnixMs, toJPYmd } from 'src/utils/time';

export interface Period {
  beginAt: number;
  endAt: number;
}

interface PeriodInputProps {
  label: string;
  placeholder?: string;
  helperText?: string;
  period: Period;
  initialValue?: string;
  isDirty: boolean;
  toggleDialog: () => void;
}

export default function PeriodInput(props: PeriodInputProps) {
  const {
    label,
    placeholder,
    helperText,
    period,
    initialValue,
    isDirty,
    toggleDialog,
  } = props;

  const beginDate = fromUnixMs(period.beginAt);
  const endDate = fromUnixMs(period.endAt);

  const sameDay = beginDate.hasSame(endDate, 'day');

  const beginDateYmd = toJPYmd(beginDate);
  const endDateYmd = toJPYmd(endDate);

  const dateRange = sameDay ? beginDateYmd : `${beginDateYmd}〜${endDateYmd}`;

  return (
    <InputField
      fullWidth
      variant="outlined"
      label={label}
      placeholder={placeholder}
      value={isDirty ? dateRange : initialValue}
      helperText={helperText && (sameDay ? helperText : ' ')}
      onClick={toggleDialog}
      sx={{
        background: 'white',

        fieldset: {
          border: '1px solid #828282',

          '& > legend': {
            fontSize: 12,
          },
        },

        '& .MuiInputBase-root': {
          height: 45,
          cursor: 'pointer',
        },
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          color: '#141414',
        },
      }}
      InputProps={{
        readOnly: true,
        name: 'period',
        endAdornment: (
          <CalendarTodaySharp sx={{ fontSize: 20, color: 'primary.main' }} />
        ),
        sx: { input: { cursor: 'pointer' } },
      }}
    />
  );
}
