import { useSelector } from 'src/domains/root/store';
import { activeWorkspaceSelector } from 'src/domains/root/features/users/slice';
import { Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router';
import ReportList from './ReportList';
import { useGetWorkspacesReports } from './useGetWorkspacesReports';

export default function ReportListContainer() {
  const workspace = useSelector(activeWorkspaceSelector);
  const result = useGetWorkspacesReports(workspace!);

  if (result.status === 'loading') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80dvh"
        p={1.25}
      >
        <CircularProgress data-testid="CircularProgress" />
      </Box>
    );
  }

  if (result.status === 'hasError') {
    return (
      <RedirectionForGetWorkspacesReprotsError errorCode={result.errorCode} />
    );
  }

  const reports = result.data.reports;

  return <ReportList reports={reports} />;
}

function RedirectionForGetWorkspacesReprotsError({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}
