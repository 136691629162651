import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ChangeEvent, ForwardedRef, forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import BaseButton from 'src/domains/root/commons/buttons/Base';
import { InputField } from 'src/shared/ui/InputField';
import SettingIcon from 'src/domains/root/commons/layout/drawer/icons/SettingIcon';
import { DownloadIcon } from 'src/domains/root/commons/icons';

import * as API from 'src/apis';
import { DateTime } from 'luxon';
import { CancelOutlined } from '@mui/icons-material';
import PeriodInput, { type Period } from './PeriodInput';
import PeriodDialog from './PeriodDialog';

const GATEWAYS_ALL = '__GATEWAYS_ALL__';

interface ReportListProps {
  reports: API.Report[];
}

export default function ReportList({ reports }: ReportListProps) {
  const initialPeriod = reports.length
    ? reports.reduce(
        (acc, cur) => ({
          beginAt: Math.min(acc.beginAt, cur.beginAt),
          endAt: Math.max(acc.endAt, cur.endAt),
        }),
        {
          beginAt: reports[0].beginAt,
          endAt: reports[0].endAt,
        },
      )
    : { beginAt: 0, endAt: 0 };

  const [period, setPeriod] = useState({
    beginAt: initialPeriod.beginAt,
    endAt: initialPeriod.endAt,
  });

  const [selectedGatewayName, setSelectedGatewayName] =
    useState<string>(GATEWAYS_ALL);

  const [searchTitle, setSearchTitle] = useState('');

  const handlePeriodChange = ({ beginAt, endAt }: Period) => {
    setPeriod({ beginAt, endAt });
  };

  const changeGatewayName = (gatewayName: string) => {
    setSelectedGatewayName(gatewayName);
  };

  const changeSearchTitle = (title: string) => {
    setSearchTitle(title);
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <SearchCondition
        reports={reports}
        initialPeriod={initialPeriod}
        period={period}
        handlePeriodChange={handlePeriodChange}
        selectedGatewayName={selectedGatewayName}
        changeGatewayName={changeGatewayName}
        searchTitle={searchTitle}
        changeSearchTitle={changeSearchTitle}
      />
      <ReportListTable
        reports={reports}
        period={period}
        selectedGatewayName={selectedGatewayName}
        searchTitle={searchTitle}
      />
    </Box>
  );
}

function SearchCondition({
  reports,
  initialPeriod,
  period,
  handlePeriodChange,
  selectedGatewayName,
  changeGatewayName,
  searchTitle,
  changeSearchTitle,
}: {
  reports: API.Report[];
  initialPeriod: Period;
  period: Period;
  handlePeriodChange: (props: Period) => void;
  selectedGatewayName: string;
  changeGatewayName: (gatewayName: string) => void;
  searchTitle: string;
  changeSearchTitle: (title: string) => void;
}) {
  const intl = useIntl();

  const [openPeriodDialog, setOpenPeriodDialog] = useState(false);
  const [isPeriodDirty, setIsPeriodDirty] = useState(false);

  const gatewayNames = Array.from(
    new Set(reports.map((report) => report.gatewayName)),
  );

  const handleGatewayNameChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    changeGatewayName(value);
  };

  const togglePeriodDialog = () => {
    setOpenPeriodDialog((prev) => !prev);
  };

  const checkPeriodDirty = () => {
    setIsPeriodDirty(true);
  };

  const handleSearchTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeSearchTitle(e.target.value);
  };

  const handleClearSearchTitle = () => {
    changeSearchTitle('');
  };

  return (
    <Box>
      <Box display="flex" columnGap={2}>
        <Box py={0.5}>
          <HeadTitle>
            {intl.formatMessage({ id: 'pages.Reports.searchCondition.title' })}
          </HeadTitle>
        </Box>
        <ClearButton disabled={true} />
      </Box>
      <Box
        pt={2.5}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={2}
      >
        <Box width={{ sm: 328 }} minWidth={{ sm: 328, lg: 244 }}>
          <PeriodInput
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.period',
            })}
            period={period}
            initialValue={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodAll',
            })}
            isDirty={isPeriodDirty}
            toggleDialog={togglePeriodDialog}
          />
        </Box>

        <PeriodDialog
          loggingId="PeriodDialog"
          open={openPeriodDialog}
          title={intl.formatMessage({
            id: 'pages.Reports.searchCondition.periodDialog.title',
          })}
          content={<Typography color="#141414"></Typography>}
          initialPeriod={initialPeriod}
          period={period}
          onConfirm={handlePeriodChange}
          onDirty={checkPeriodDirty}
          confrimText={intl.formatMessage({
            id: 'pages.Reports.searchCondition.periodDialog.searchButton',
          })}
          close={togglePeriodDialog}
        />

        <SelectFormControl>
          <SelectInputLabel htmlFor="gatewayName">
            {intl.formatMessage({
              id: 'pages.Reports.searchCondition.gatewayName',
            })}
          </SelectInputLabel>
          <StyledSelect
            value={selectedGatewayName}
            onChange={handleGatewayNameChange}
            labelId="gatewayName"
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.gatewayName',
            })}
            name="gatewayName"
          >
            <SelectMenuItem value={GATEWAYS_ALL}>
              <MenuItemText>
                {intl.formatMessage({
                  id: 'pages.Reports.searchCondition.gatewayNameAll',
                })}
              </MenuItemText>
            </SelectMenuItem>
            {gatewayNames.map((gatewayName) => (
              <SelectMenuItem key={gatewayName} value={gatewayName}>
                <MenuItemText>{gatewayName}</MenuItemText>
              </SelectMenuItem>
            ))}
          </StyledSelect>
        </SelectFormControl>
        <InputField
          label={intl.formatMessage({
            id: 'pages.Reports.searchCondition.reportTitle',
          })}
          placeholder={intl.formatMessage({
            id: 'pages.Reports.searchCondition.reportTitlePlaceholder',
          })}
          value={searchTitle}
          onChange={handleSearchTitleChange}
          InputProps={{
            endAdornment: searchTitle && (
              <CancelOutlined
                color="primary"
                onClick={handleClearSearchTitle}
                sx={{ cursor: 'pointer' }}
              />
            ),
          }}
          sx={{
            backgroundColor: 'common.white',
            borderRadius: 1,
            maxWidth: { sm: 328, lg: '100%' },

            '& .MuiInputBase-root': {
              '& fieldset': {
                borderColor: '#828282',
              },
            },

            '& .MuiInputBase-input': {
              height: 28, // content-box, py: 8.5px + height: 28px = 45px
            },

            '& .MuiInputLabel-root': {
              color: '#141414',
            },
          }}
        />
      </Box>
    </Box>
  );
}

function ClearButton({ disabled }: { disabled: boolean }) {
  const intl = useIntl();

  return (
    <BaseButton
      variant="outlined"
      disabled={disabled}
      sx={{
        px: 2.75,
        py: 7 / 8,

        backgroundColor: 'common.white',
        borderColor: 'primary.main',
        color: 'primary.main',

        '&:hover': {
          backgroundColor: 'primary.main',
          borderColor: 'primary.main',
          color: 'common.white',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: 'primary.main',
          color: 'primary.main',
        },
      }}
    >
      {intl.formatMessage({
        id: 'pages.Reports.searchCondition.clearButton',
      })}
    </BaseButton>
  );
}

function SelectFormControl({ children }: { children: React.ReactNode }) {
  return (
    <FormControl
      variant="outlined"
      sx={{
        height: 45,
        borderRadius: 1,
        width: { lg: '100%' },
        maxWidth: { sm: 328, lg: '100%' },
        backgroundColor: 'common.white',
      }}
    >
      {children}
    </FormControl>
  );
}

function SelectInputLabel({
  htmlFor,
  children,
}: {
  htmlFor: string;
  children: React.ReactNode;
}) {
  return (
    <InputLabel
      htmlFor={htmlFor}
      sx={{
        fontSize: 12,
        color: '#141414',

        // muiがscale(0.75)に設定したせいでカスタムしづらい、のでscale(1)にする
        transform: 'translate(14px, -7px) scale(1)',
      }}
    >
      {children}
    </InputLabel>
  );
}

function StyledSelect({
  value,
  onChange,
  labelId,
  label,
  name,
  children,
}: {
  value: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  labelId: string;
  label: string;
  name: string;
  children: React.ReactNode;
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      labelId={labelId}
      label={label}
      inputProps={{
        name: name,
        id: labelId,
      }}
      sx={{
        height: 45,

        fieldset: {
          border: '1px solid #828282',

          '& > legend': {
            fontSize: 12,
          },
        },
      }}
    >
      {children}
    </Select>
  );
}

const SelectMenuItem = forwardRef<
  HTMLLIElement,
  { value: string; children?: React.ReactNode }
>(
  (
    props: { value: string; children?: React.ReactNode },
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { children, ...rest } = props;

    return (
      <MenuItem
        {...rest}
        ref={ref}
        sx={{
          p: 1,
          mx: 1,
          borderRadius: 1,
          color: '#141414',

          ':hover': {
            backgroundColor: 'primary.light',
          },

          ':focus-visible': {
            backgroundColor: 'primary.light',
          },

          '&.Mui-selected': {
            backgroundColor: 'primary.main',
            color: 'common.white',
            fontWeight: 'bold',

            ':hover': {
              backgroundColor: (theme) => `${theme.palette.primary.main}50`,
            },

            ':focus-visible': {
              backgroundColor: 'primary.main',
            },

            ':focus-visible:hover': {
              backgroundColor: (theme) => `${theme.palette.primary.main}50`,
            },
          },
        }}
      >
        {children}
      </MenuItem>
    );
  },
);

SelectMenuItem.displayName = 'SelectMenuItem';

function MenuItemText({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="inherit" noWrap>
      {children}
    </Typography>
  );
}

function ReportListTable({
  reports,
  period,
  selectedGatewayName,
  searchTitle,
}: {
  reports: API.Report[];
  period: Period;
  selectedGatewayName: string;
  searchTitle: string;
}) {
  const intl = useIntl();

  const isReportInPeriod = (report: API.Report): boolean => {
    return report.endAt >= period.beginAt && report.beginAt <= period.endAt;
  };

  const isSelectedGatewayName = (report: API.Report): boolean => {
    if (selectedGatewayName === GATEWAYS_ALL) {
      return true;
    }

    return report.gatewayName === selectedGatewayName;
  };

  const isSearchTitleIncluded = (report: API.Report): boolean => {
    return report.title.includes(searchTitle);
  };

  const filteredReports = reports
    .filter(isReportInPeriod)
    .filter(isSelectedGatewayName)
    .filter(isSearchTitleIncluded);

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" columnGap={1.5}>
          <HeadTitle>
            {intl.formatMessage({
              id: 'pages.Reports.reportListTable.searchResult',
            })}
          </HeadTitle>
          <Typography>
            {filteredReports.length}
            {intl.formatMessage({
              id: 'pages.Reports.reportListTable.counts',
            })}
          </Typography>
        </Box>
        <CreateNewReportButton />
      </Box>
      <Box
        bgcolor="common.white"
        borderRadius={1}
        border={{ xs: 'none', sm: '1px solid #828282' }}
        p={3}
        overflow={{ xs: 'auto', sm: 'unset' }}
      >
        <TableContainer>
          <Table>
            <ReportListTableHead />
            <TableBody>
              {filteredReports.map((report) => (
                <TableRow key={report.reportId}>
                  <TableCell
                    align="center"
                    sx={{ fontSize: { xs: 13, sm: 14 } }}
                  >
                    {DateTime.fromMillis(report.beginAt).toFormat(
                      'yyyy/MM/dd HH:mm',
                    )}
                    <br />
                    ~
                    <br />
                    {DateTime.fromMillis(report.endAt).toFormat(
                      'yyyy/MM/dd HH:mm',
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      minWidth: { xs: 140, sm: 200 },
                      fontSize: { xs: 13, sm: 16 },
                    }}
                  >
                    {report.title}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      minWidth: { xs: 160, sm: 200 },
                      fontSize: { xs: 13, sm: 16 },
                    }}
                  >
                    {report.gatewayName}
                  </TableCell>
                  <TableCell align="center">
                    <SettingIcon />
                  </TableCell>
                  <TableCell align="center">
                    <DownloadIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

function ReportListTableHead() {
  const intl = useIntl();

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            pt: 1.25,
            pb: 3.25,
            width: 75,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.period',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 1.25,
            pb: 3.25,
            pl: { xs: 2.5, sm: 2 },
            pr: { xs: 6, sm: 2 },
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.reportTitle',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 1.25,
            pb: 3.25,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.gatewayName',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 1.25,
            pb: 3.25,
            minWidth: 70,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.operation',
          })}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            pt: 0,
            pb: 2,
            minWidth: 84,
            width: 84,
            fontSize: 15,
            fontWeight: 700,
            color: '#282828',
          }}
        >
          {intl.formatMessage({
            id: 'pages.Reports.reportListTable.download',
          })}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function CreateNewReportButton() {
  const intl = useIntl();

  return (
    <BaseButton
      variant="contained"
      sx={{
        px: 3,
        py: 7 / 8,
        backgroundColor: 'primary.main',
        color: 'common.white',
        boxShadow: 'none',

        '&:hover': {
          backgroundColor: 'nito.main',
          color: 'common.white',
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: 'primary.main',
          color: 'common.white',
        },
      }}
    >
      {intl.formatMessage({
        id: 'pages.Reports.reportListTable.create',
      })}
    </BaseButton>
  );
}

function HeadTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      pl={1.5}
      fontSize={18}
      fontWeight="bold"
      borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
    >
      {children}
    </Typography>
  );
}
