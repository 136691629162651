import MainContents from 'src/domains/root/commons/layout/MainContents';
import ReportsMakeNewContainer from '../features/reports/ReportsMakeNewContainer';

export default function ReportsNew() {
  return (
    <MainContents>
      <ReportsMakeNewContainer />
    </MainContents>
  );
}
